import React from 'react';
import { FirebaseAuthConsumer } from '@react-firebase/auth';
import { Redirect, Route } from 'react-router-dom';

import { routesList } from '../';
import { LayoutView } from '../../components/Layout';
import { ACCESS_TOKEN_KEY } from '../../constants';

export const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
      <FirebaseAuthConsumer>
        {() => {
          const isAccessToken = localStorage.getItem(ACCESS_TOKEN_KEY);

          if (!isAccessToken) {
            return (
                <Redirect
                    to={{
                      pathname: routesList.login,
                    }}
                />
            );
          }

          return (
              <LayoutView>
                <Route {...rest} render={(props) => <Component {...props} />} />
              </LayoutView>
          );
        }}
      </FirebaseAuthConsumer>
  );
};
