import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export const Spinner = ({ children = null, isLoading }) => (
    <Spin spinning={isLoading} indicator={antIcon}>
        {children}
    </Spin>
);
