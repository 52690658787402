import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { FirebaseAuthConsumer } from '@react-firebase/auth';

import { routesList } from '../';
import { ACCESS_TOKEN_KEY } from '../../constants';


export const PublicRoute = ({ component: Component, ...rest }) => {
  return (
      <FirebaseAuthConsumer>
        {() => {
          const isAccessToken = localStorage.getItem(ACCESS_TOKEN_KEY);

          if (isAccessToken) {
            return (
                <Redirect
                    to={{
                      pathname: routesList.profile,
                    }}
                />
            );
          }

          return <Route {...rest} render={(props) => <Component {...props} />} />;
        }}
      </FirebaseAuthConsumer>
  );
};
