import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useWindowListeners } from "../../../core";

export const useLayout = () => {
  const history = useHistory();

  const { currentClientWidth } = useWindowListeners();
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    if (currentClientWidth >= 1024) {
      setIsCollapsed(false);
    }
  }, [currentClientWidth]);

  const switchCollapse = useCallback(() => {
    setIsCollapsed(!isCollapsed)
  }, [isCollapsed, setIsCollapsed])

  const handleSelectedMenuItem = (item, key) => {
    const clientWidth = document.documentElement.clientWidth;
    if (clientWidth < 1024) {
      switchCollapse();
    }
    history.push(key);
  }

  const isResponsive = currentClientWidth < 1024;
  const isOpenResponsiveSider = isResponsive && isCollapsed;

  return {
    handleSelectedMenuItem,
    isResponsive,
    isOpenResponsiveSider,
    switchCollapse,
  }
}