import React from 'react';
import { Link } from 'react-router-dom';
import { Result, Button } from 'antd';

import { ACCESS_TOKEN_KEY } from '../../constants';
import { routesList } from '../';

export const NotFound = () => {
  const authValue = localStorage.getItem(ACCESS_TOKEN_KEY);
  const buttonText = authValue === 'true' ? 'Back Dashboard' : 'Back Login';
  const route = authValue === 'true' ? routesList.dashboard : routesList.login;

  return (
      <Result
          status="404"
          title="404"
          subTitle="Sorry, the page you visited does not exist."
          extra={
            <Link to={route}>
              <Button type="primary">{buttonText}</Button>
            </Link>
          }
      />
  );
};
