import { useEffect, useState } from 'react';

export const useWindowListeners = () => {
  const [currentClientWidth, setCurrentClientWidth] = useState(0);

  useEffect(() => {
    function listenWindowResize() {
      const clientWidth = document.documentElement.clientWidth;
      setCurrentClientWidth(clientWidth);
    }
    window.addEventListener('resize', listenWindowResize);

    return () => {
      window.removeEventListener('resize', listenWindowResize);
    };
  }, []);

  return {
    currentClientWidth
  };
};
