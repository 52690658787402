import React, {useState} from 'react';
import {leftSideMenuRoutes} from './routes';

import {AppContext} from './AppContext';

const AppProvider = ({children}) => {

  const initialValues = {
    menu: leftSideMenuRoutes,
    currentCourse: null,
  };

  const [
    {
      menu,
      currentCourse,
    }, updateContext
  ] = useState(initialValues);

  const [
    selectedMenuItem,
    setMenuItem,
  ] = useState(leftSideMenuRoutes[0].route);

  const resetLayoutContext = () => {
    updateContext(() => ({...initialValues}));
  };

  const updateLayoutContext = (values) => {
    updateContext(prevState => ({...prevState, ...values}));
  };

  return (
      <AppContext.Provider
          value={{
            menu,
            setMenuItem,
            currentCourse,
            selectedMenuItem,
            resetLayoutContext,
            updateLayoutContext,
          }}
      >
        {children}
      </AppContext.Provider>
  );
};

export default AppProvider;
