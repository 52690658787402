import React, {useEffect} from 'react';
import {Layout, Menu} from 'antd';
import {Link, useHistory} from 'react-router-dom';
import {MenuOutlined, LogoutOutlined, StepBackwardFilled} from '@ant-design/icons';
import firebase from 'firebase/app';
import cn from 'classnames';

import {Logo} from '../common/Logo';
import {useApp} from '../../AppContext';
import {routesList} from '../../routes';
import {ACCESS_TOKEN_KEY} from '../../constants';
import {useLayout} from "./hooks";
import './Layout.css';

const {Header, Content, Sider} = Layout;

const {SubMenu} = Menu;

export const LayoutView = ({children}) => {
  const history = useHistory();

  const {location: {pathname: currentPath}} = history;
  const {menu: leftMenuRoutes, selectedMenuItem, currentCourse, setMenuItem} = useApp();
  const {
    handleSelectedMenuItem,
    isResponsive,
    isOpenResponsiveSider,
    switchCollapse,
  } = useLayout();

  useEffect(() => {
    setMenuItem(currentPath);
  }, [currentPath]);

  const handleLogOut = () => {
    firebase
        .auth()
        .signOut()
        .then(() => {
          localStorage.removeItem(ACCESS_TOKEN_KEY);
          history.push(routesList.login);
        });
  };

  const populateMenu = (route, label) => {
    return <Menu.Item key={route}>
      <Link to={route} onClick={handleSelectedMenuItem}>{label}</Link>
    </Menu.Item>;
  };

  const siderClassName = cn('header_sider', {
    'header_sider_is_responsive': isResponsive,
    'header_sider_is_responsive_open': isOpenResponsiveSider,
  })

  return (
      <Layout style={{minHeight: '100vh'}}>
        <Header>
          <div className="header__menu-outlined">
            <MenuOutlined onClick={switchCollapse} style={{ color: '#ffffff' }}/>
          </div>
          <div className="header__logo">
            <Link to={routesList.profile}>
              <Logo/>
            </Link>
          </div>
          <div className="header__logout">
            <LogoutOutlined onClick={handleLogOut}/>
          </div>
        </Header>
        <Layout>
          <Sider
            width={200}
            className={siderClassName}
          >
            {currentCourse ? <div className="site-layout-current-course">
              <Link to={`/`} className="site-layout-current-course-link" onClick={handleSelectedMenuItem}>Главная</Link>
              <Link className="site-layout-current-course-link"
                  to={`/courses/${currentCourse.id}`} onClick={handleSelectedMenuItem}>Курс: {currentCourse.title}</Link>
            </div> : null}
            <Menu theme="dark" mode="inline" selectedKeys={[selectedMenuItem]}>
              {leftMenuRoutes.map(
                  ({route, label, items}, index) => {
                    if (!route && items) {
                      return <SubMenu key={`block_${index + 1}`} title={label}>
                        {
                          items.map(
                              ({route, label}) => populateMenu(route, label))
                        }
                      </SubMenu>;
                    }
                    return populateMenu(route, label);
                  },
              )}
            </Menu>
          </Sider>
          {!isOpenResponsiveSider && <Layout style={{padding: '0 24px 24px'}}>
            <Content
                className="site-layout-background"
                style={{
                  padding: 24,
                  margin: 0,
                }}
            >
              {children}
            </Content>
          </Layout>}
        </Layout>
      </Layout>
  );
};
