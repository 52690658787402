import './App.css';

import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { Spinner } from './components';
import { NotFound, PublicRoute, PrivateRoute, routesList } from './routes';

// const ProfilePage = lazy(() => import('./views/profile/pages/Profile'));
const LoginPage = lazy(() => import('./views/auth/pages/Login'));
const CoursesPage = lazy(() => import('./views/course/pages/Courses'));
const CoursePage = lazy(() => import('./views/course/pages/Course'));
const LessonPage = lazy(() => import('./views/lesson/pages/Lesson'));

function App() {
  return (
      <Router>
        <Suspense fallback={<Spinner isLoading />}>
          <Switch>
            {/*<PrivateRoute*/}
            {/*    exact*/}
            {/*    path={routesList.profile}*/}
            {/*    component={ProfilePage}*/}
            {/*/>*/}
            <PrivateRoute
                exact
                path={routesList.course}
                component={CoursePage}
            />
            <PrivateRoute
                exact
                path={routesList.profile}
                component={CoursesPage}
            />
            <PrivateRoute
                exact
                path={routesList.lesson}
                component={LessonPage}
            />
            <PublicRoute exact path={routesList.login} component={LoginPage} />
            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </Router>
  );
}

export default App;
