export const routesList = {
  profile: '/',
  courses: '/courses',
  login: '/login',
  course: '/courses/:id',
  lesson: '/lesson/:id',
};

export const leftSideMenuRoutes = [
  // {
  //   route: routesList.profile,
  //   label: 'Профиль',
  // },
  {
    route: routesList.profile,
    label: 'Мои курсы',
  }
];

export { NotFound, PublicRoute, PrivateRoute } from './components';
