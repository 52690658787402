import axios from 'axios';

import { ACCESS_TOKEN_KEY } from '../constants';
import { routesList } from '../routes';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API || 'http://localhost:3000/api',
});

instance.interceptors.request.use((config) => {
  const token = localStorage.getItem(ACCESS_TOKEN_KEY);

  return {
    ...config,
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
});

instance.interceptors.response.use(
    (response) => response.data,
    (error) => {
      const status = error.response && error.response.status;

      if (status === 401) {
        localStorage.removeItem(ACCESS_TOKEN_KEY);
        window.location.href = routesList.login;
      }
    },
);

export default instance;
